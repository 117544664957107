import { AddThreadButton, useAddThreadButtonProps } from "../../widgets/add-thread-button";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import {
  GqlOps,
  L_Vouch_Level,
  RelationshipStatusBySlugQuery,
  Relationship_Statuses_Enum,
  Relationship_Types_Enum,
  useBioV2FromSlugBasicsQuery,
  useCanInitiateThreadQuery,
  useHideDiscoveryResult300Mutation,
  useRelationshipStatusBySlugQuery,
  useUserNewInAppNotifications510Query,
} from "shared/dist/__generated__/components";
import {
  MultipleTypeRelationshipControlButton,
  NonPartnerRelationshipTypeControl,
} from "../../widgets/add-relationship-button";
import { NewVouchLevelModal, NewVouchReceivedPopup } from "./vouching/vouch-alerts";
import { P, match } from "ts-pattern";
import { PronounPair, useSlugPronouns } from "shared/dist/pronouns";
import {
  faCircleXmark,
  faHeart,
  faMessage,
  faPencil,
  faUserPlus,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faHeartCirclePlus,
  faEnvelope,
  faHeartCircleXmark,
  faPaperPlane,
} from "@fortawesome/pro-regular-svg-icons";
import { getVouchLevel, isVouchLevelAchieved } from "shared/dist/util/get-vouch-level";
import { useIsMyPage, useMyInfo } from "../bio/bio-settings";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { BigModal } from "../../widgets/bio/big-modal";
import { Container } from "../../widgets/container";
import { DateTime } from "luxon";
import { H4 } from "shared-web-react/dist/widgets/text";
import { IconButtonWrapper } from "../../widgets/icon-button-wrapper";
import { IndicatorNavLink } from "../../widgets/indicator-nav-link";
import { Maybe } from "shared/dist/util";
import { QrCodeToShare } from "../../widgets/bio/share-qr-code";
import React from "react";
import { SpinnerButton } from "../../widgets/spinner-button";
import { SpinnerButtonProps } from "shared-web-react/dist/widgets/spinner-button";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { UserPageContainer } from "./common";
import { VerificationBox } from "../../widgets/verification-box";
import { VerifiedIdentityIcon } from "./settings/identity";
import { VouchControl } from "./vouching/vouch-controls";
import { VouchSimpleInfo } from "./vouching/vouch-info-box";
import { allRoutes } from "../../util/routes";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { faQrcode } from "@fortawesome/sharp-solid-svg-icons";
import { faUpFromBracket } from "@fortawesome/pro-solid-svg-icons";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useIdentityVerifiedStatus } from "./settings/identity/common";
import { useMkUrl } from "shared/dist/util/env";
import { useMyId } from "shared/dist/auth-data";
import { useRelationshipFunctions } from "shared/dist/connections/relationships";
import { useShare } from "../../widgets/share";
import { useShouldShowNavStackHeaderState } from "shared-web-react/dist/widgets/nav-stack-view";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { LazyImage } from "shared-web-react/dist/widgets/lazy-image";
import { useProfileImageUrl } from "shared-web-react/dist/util/url-helpers";
import { Button } from "../../components/atoms/Button";
import { Text } from "../../components/atoms/Text";
import mixpanel from "mixpanel-browser";
import { SuccessTakeover } from "./match-success";
import { set } from "ts-pattern/dist/patterns";

type BasicsV1OnlyProps = {
  stat_one_label: string;
  stat_one_value: number;
  stat_two_label: string;
  stat_two_value: number;
};
type BasicsCommonProps = {
  age?: number | null;
  gender?: string | null;
  orientation?: string | null;
  bio?: string | null;
  status?: string | null;
  verified: boolean;
  slug: string;
  editable?: boolean;
  screen_name: string;
  profile_image_id?: string;
};
type BasicsProps = BasicsCommonProps & BasicsV1OnlyProps;
type BasicsPropsV2 = BasicsCommonProps & {
  friend_count: number;
  vouchCount: number;
  vouchLevel: Maybe<L_Vouch_Level>;
  tagline: string | null | undefined;
  mediaCount: number;
  partnered: boolean;
};

type SlugProp = { slug: string; className?: string };
type SlugPronounProp = SlugProp & { pronouns: PronounPair | null; text?: string };

enum ConnectButtonStages {
  INITIAL = "initial",
  MATCH_OR_FRIEND = "match_or_friend",
  // MESSAGE = "message",
}

enum PendingConnection {
  INITIAL = "initial",
  CANCEL_OR_ACCEPT = "cancel_or_accept",
  // MESSAGE = "message",
}

function BasicsStatus({
  editable,
  status,
}: { status?: null | string } & Pick<BasicsProps, "editable">) {
  // let { slug } = useParams();
  // const trigger = useTriggerAddContentMenu();
  return (
    <div className="max-w-full flex max-lg:items-center flex-col ">
      {/* {status && editable ? (
        <a className="max-lg:pl-2" onClick={trigger}>
          {status}
          <span className="fa-stack -ml-2 -mt-1">
            <FontAwesomeIcon className="fa-stack-1x" icon={faCircle} fixedWidth />
            <FontAwesomeIcon
              className="fa-stack-1x fa-inverse"
              style={{ fontSize: "0.5em" }}
              icon={faPencil}
              fixedWidth
            />
          </span>
        </a>
      ) : ( */}
      <p className="sm italic">{status}</p>
      {/* )} */}
    </div>
  );
}

function BasicsButton({ children }: React.PropsWithChildren<{}>) {
  return (
    <IconButtonWrapper className={classNames("text-2xl lg:text-4xl h-9 w-9 lg:h-14 lg:w-14")}>
      {children}
    </IconButtonWrapper>
  );
}

function ActionsForMyPageV2({
  screenName,
  slug,
}: Pick<BioBasicsActionsProps, "slug" | "screenName">): React.JSX.Element {
  const [showWidget, setShowWidget] = React.useState<null | "qr">(null);
  const mkUrl = useMkUrl();
  const share = useShare();
  // const { verified } = useIdentityVerifiedStatus();

  // if (!verified) {
  //   return (
  //     <VerificationBox
  //       heading="🪪 Verify Your Identity 🪪"
  //       text="To maintain trust and safety, all users of Simmr need to verify their profile with government ID before connecting to other members"
  //     />
  //   );
  // }
  return (
    <ActionsButtonsContainer>
      <ActionsButton
        className="!shrink !grow-0 btn-square aspect-square"
        outline
        onClick={() => setShowWidget("qr")}
        icon={faQrcode}
      />
      <ActionsButton icon={faPencil} outline to={allRoutes.PROFILE.ABOUT.buildPath({ slug })}>
        Edit Profile
      </ActionsButton>
      <ActionsButton
        icon={faUpFromBracket}
        onClick={() => {
          share({
            url: mkUrl(window.location.pathname),
            title: `${screenName} on Simmr`,
            fallbackText: mkUrl(window.location.pathname),
          });
        }}
      >
        Share
      </ActionsButton>
      {showWidget === "qr" && (
        <BigModal onDismiss={() => setShowWidget(null)}>
          <QrCodeToShare />
        </BigModal>
      )}
    </ActionsButtonsContainer>
  );
}

function ActionsForMyPageV1({
  screenName,
}: Pick<BioBasicsActionsProps, "screenName">): React.JSX.Element {
  const [showWidget, setShowWidget] = React.useState<null | "qr">(null);
  const mkUrl = useMkUrl();
  const share = useShare();
  return (
    <>
      <BasicsButton>
        <button
          onClick={() => {
            share({
              url: mkUrl(window.location.pathname),
              title: `${screenName} on Simmr`,
              fallbackText: mkUrl(window.location.pathname),
            });
          }}
        >
          <FontAwesomeIcon icon={faUpFromBracket} fixedWidth />
        </button>
      </BasicsButton>
      <BasicsButton>
        <button onClick={() => setShowWidget("qr")}>
          <FontAwesomeIcon icon={faQrcode as any} fixedWidth />
        </button>
      </BasicsButton>
      {showWidget === "qr" && (
        <BigModal onDismiss={() => setShowWidget(null)}>
          <QrCodeToShare />
        </BigModal>
      )}
    </>
  );
}

function useRelFunctionsWithToast(setLoading?: (loading: boolean) => void) {
  const addToast = useAddToast();
  return useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });
}

const Tp = Relationship_Types_Enum;
const St = Relationship_Statuses_Enum;

type ActionsCommonProps<S extends Relationship_Statuses_Enum, T extends Relationship_Types_Enum> =
  | {
      slug: string;
      fromMe?: boolean;
      rel?: undefined;
    }
  | {
      slug: string;
      fromMe: S extends Relationship_Statuses_Enum.Accepted ? undefined | null | boolean : boolean;
      rel: Omit<
        RelationshipStatusBySlugQuery["relationships"][0],
        "__typename" | "status" | "type"
      > & {
        status: S;
        type: T;
      };
    };

export function TestBioActions(): React.JSX.Element {
  const slug = "test";
  const screenName = "test screenname";
  const args = {
    created_at: DateTime.now().toISO()!,
    from_user_id: "0000",
    id: "0000",
  };
  return (
    <div className={clsx("w-full space-y-2 pt-4 pb-64 px-2 bg-neutral")}>
      No connection:
      <ActionsRouter {...{ screenName, slug }} fromMe />
      <div className="divider" />
      Partners
      {/* prettier-ignore */}
      <ActionsRouter {...{ screenName, slug }} fromMe rel={{ ...args, type: Tp.Partner, status: St.Accepted }} />
      <div className="divider" />
      Like Sent:
      {/* prettier-ignore */}
      <ActionsRouter {...{ screenName, slug }} fromMe rel={{ ...args, type: Tp.Like, status: St.Requested }} />
      <div className="divider" />
      Like Received:
      <ActionsRouter
        {...{ screenName, slug }}
        rel={{ ...args, type: Tp.Like, status: St.Requested }}
      />
      <div className="divider" />
      Like Mutual:
      <ActionsRouter
        {...{ screenName, slug }}
        rel={{ ...args, type: Tp.Like, status: St.Accepted }}
      />
      <div className="divider" />
      Friend Sent
      <ActionsRouter
        {...{ screenName, slug }}
        fromMe
        rel={{ ...args, type: Tp.Friend, status: St.Requested }}
      />
      <div className="divider" />
      Friend Received
      <ActionsRouter
        {...{ screenName, slug }}
        rel={{ ...args, type: Tp.Friend, status: St.Requested }}
      />
      <div className="divider" />
      Friend Mutual
      <ActionsRouter
        {...{ screenName, slug }}
        rel={{ ...args, type: Tp.Friend, status: St.Accepted }}
      />
      <div className="divider" />
    </div>
  );
}

function ActionsButtonsContainer({ children }: React.PropsWithChildren<{}>): React.JSX.Element {
  return (
    <Container
      size="xs"
      className={clsx(
        "ActionsButtonsContainer",
        "w-full whitespace-nowrap flex-nowrap  flex-0 flex-row-center-center gap-1"
      )}
    >
      {children}
    </Container>
  );
}
function ActionsSection({ children }: React.PropsWithChildren<{}>): React.JSX.Element {
  return (
    <section className="w-full bg-[#2A2C38] py-2 flex-col items-center justify-stretch gap-1 ">
      {children}
    </section>
  );
}

type ActionsButtonProps = { outline?: boolean } & SpinnerButtonProps &
  Partial<Pick<FontAwesomeIconProps, "icon">> & {
    to?: string;
  };
function ActionsButton({
  outline,
  className,
  to,
  children,
  icon,
  ...props
}: ActionsButtonProps): React.JSX.Element {
  const baseStyles = "h-10 px-4 text-base leading-none flex items-center justify-center";

  if (to) {
    return (
      <Link to={to}>
        <Button
          variant={outline ? "outline" : "standard"}
          className={clsx(baseStyles, outline && "btn-outline", className)}
          {...props}
        >
          {icon && <FontAwesomeIcon icon={icon} className="mr-1" />}
          {children}
        </Button>
      </Link>
    );
  }

  return (
    <Button
      variant={outline ? "outline" : "standard"}
      className={clsx(baseStyles, outline && "btn-outline", className)}
      {...props}
    >
      {icon && <FontAwesomeIcon icon={icon} className="mr-1" />}
      {children}
    </Button>
  );
}

function ActionsFriendSent({
  slug,
}: ActionsCommonProps<
  Relationship_Statuses_Enum.Requested,
  Relationship_Types_Enum.Friend
>): React.JSX.Element {
  const { cancelRequest, initiateRequest } = useRelFunctionsWithToast();
  return (
    <ActionsSection>
      <ActionsButtonsContainer>
        <ActionsButton
          outline
          childrenDivClassName="flex-1 flex-row-center-center gap-1"
          onClick={() => cancelRequest(slug, Tp.Friend)}
        >
          Cancel Request
        </ActionsButton>
      </ActionsButtonsContainer>
    </ActionsSection>
  );
}

function ActionsFriendReceived({
  slug,
  screenName,
}: { screenName: string } & ActionsCommonProps<
  Relationship_Statuses_Enum.Requested,
  Relationship_Types_Enum.Friend
>): React.JSX.Element {
  const { acceptRequest, denyRequest, refetchQueriesManually } = useRelFunctionsWithToast();
  const [buttonStages, setButtonStages] = React.useState(PendingConnection.INITIAL);
  const [showWidget, setShowWidget] = React.useState<boolean>(false);

  if (showWidget)
    return (
      <SuccessTakeover
        slug={slug}
        refetch={refetchQueriesManually}
        onDismiss={() => setShowWidget(false)}
      />
    );

  return (
    <ActionsSection>
      {match(buttonStages)
        .with(PendingConnection.INITIAL, () => (
          <ActionsButtonsContainer>
            <ActionsButton onClick={() => setButtonStages(PendingConnection.CANCEL_OR_ACCEPT)}>
              Connection Request Pending
            </ActionsButton>
          </ActionsButtonsContainer>
        ))
        .with(PendingConnection.CANCEL_OR_ACCEPT, () => (
          <ActionsButtonsContainer>
            <ActionsButton outline onClick={() => denyRequest(slug, Tp.Friend)}>
              Decline Request
            </ActionsButton>
            <ActionsButton
              onClick={() => {
                setShowWidget(true);
                acceptRequest(slug, Tp.Friend, true);
              }}
            >
              Accept Request
            </ActionsButton>
          </ActionsButtonsContainer>
        ))
        .exhaustive()}
    </ActionsSection>
  );
}

function ActionsLikeSent({
  slug,
}: ActionsCommonProps<
  Relationship_Statuses_Enum.Requested,
  Relationship_Types_Enum.Like
>): React.JSX.Element {
  const { pronouns } = useSlugPronouns(slug);
  const { cancelRequest } = useRelFunctionsWithToast();
  const objectPronoun = pronouns?.obj ?? "them";
  return (
    <ActionsSection>
      <ActionsButtonsContainer>
        <ActionsButton onClick={() => cancelRequest(slug, Tp.Like)} outline>
          Cancel Request
        </ActionsButton>
        {/* <ActionsButton className={clsx("italic")}>what to put here?</ActionsButton> */}
      </ActionsButtonsContainer>
    </ActionsSection>
  );
}

function ActionsLikeReceived({
  slug,
}: ActionsCommonProps<
  Relationship_Statuses_Enum.Requested,
  Relationship_Types_Enum.Like
>): React.JSX.Element {
  const { pronouns } = useSlugPronouns(slug);
  const { acceptRequest, denyRequest } = useRelFunctionsWithToast();
  const [buttonStages, setButtonStages] = React.useState(PendingConnection.INITIAL);

  return (
    <ActionsSection>
      <ActionsButtonsContainer>
        {/* <Button variant="standard" onClick={() => acceptRequest(slug, Tp.Like)}>
          Connection Request Pending
        </Button> */}
        {match(buttonStages)
          .with(PendingConnection.INITIAL, () => (
            <ActionsButton onClick={() => setButtonStages(PendingConnection.CANCEL_OR_ACCEPT)}>
              Connection Request Pending
            </ActionsButton>
          ))
          .with(PendingConnection.CANCEL_OR_ACCEPT, () => (
            <>
              <ActionsButton outline onClick={() => denyRequest(slug, Tp.Like)}>
                Decline Request
              </ActionsButton>
              <ActionsButton onClick={() => acceptRequest(slug, Tp.Like)}>
                Accept Request
              </ActionsButton>
            </>
          ))
          .exhaustive()}
      </ActionsButtonsContainer>
    </ActionsSection>
  );
}

function ActionsLikeMutual({
  slug,
  rel,
}: ActionsCommonProps<
  Relationship_Statuses_Enum.Accepted,
  Relationship_Types_Enum.Like
>): React.JSX.Element {
  const { pronouns } = useSlugPronouns(slug);
  const { disconnect } = useRelFunctionsWithToast();
  // const subjPronoun = pronouns?.subj ?? "they";
  if (!rel) return <></>;
  return (
    <ActionsSection>
      <ActionsButtonsContainer>
        <ActionsAddThreadButton slug={slug} />
        <VouchControl
          size="full"
          slug={slug}
          label={"Vouch for " + (pronouns?.obj ?? "them")}
          labelVouched={"Vouched for " + (pronouns?.obj ?? "them")}
          outlined={true}
        />
      </ActionsButtonsContainer>
      {/* <button onClick={() => disconnect(slug, rel.type)} className={clsx("link italic text-sm ")}>
        disconnect
      </button> */}
    </ActionsSection>
  );
}

export function useRequireVerificationForProfilePageViewer(ifVerifiedFunction: () => Promise<any>) {
  const { loading, verified } = useIdentityVerifiedStatus();
  const confirm = useConfirm();
  const slug = useTypedParams(allRoutes.PROFILE).slug;
  const navigate = useNavigate();
  return React.useCallback(async () => {
    if (loading) return;
    if (verified) return ifVerifiedFunction();
    return new Promise((resolve) => {
      confirm({
        title: "Verify Your Identity",
        content:
          "To maintain trust and safety, all users of Simmr need to verify their profile with government ID before messaging to other members",
        okButtonContent: "Verify ID",
        onCancel: async () => resolve(null),
        onOk() {
          navigate(allRoutes.SETTINGS.PRIVACY.buildPath({}));
          return resolve(null);
        },
      });
    });
  }, [ifVerifiedFunction, navigate, confirm, verified, loading]);
}

function RequestMatchButton({ slug, pronouns, className }: SlugPronounProp): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const addToast = useAddToast();
  const { initiateRequest } = useRelFunctionsWithToast(setLoading);
  const wrappedInitiate = useRequireVerificationForProfilePageViewer(async () => {
    await initiateRequest(slug, Tp.Like);
    addToast({ content: "Like sent", color: "success" });
  });
  return (
    <ActionsButton outline onClick={wrappedInitiate}>
      Like
    </ActionsButton>
  );
}

function ActionsAddThreadButton({ slug, className }: SlugProp): React.JSX.Element {
  const sendProps = useAddThreadButtonProps(slug);
  return (
    <ActionsButton {...sendProps} className={className}>
      <FontAwesomeIcon icon={faEnvelope} size="lg" />
      Message
    </ActionsButton>
  );
}

function AddFriendButton({ slug, pronouns, className, text }: SlugPronounProp): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  // const addToast = useAddToast();
  const { initiateRequest } = useRelFunctionsWithToast(setLoading);
  // const wrappedInitiate = useRequireVerificationForProfilePageViewer(async () => {
  //   await initiateRequest(slug, Tp.Friend);
  //   // addToast({ content: "Request sent" });
  // });
  const wrappedInitiate = async () => {
    await initiateRequest(slug, Tp.Friend);
  };
  return <ActionsButton onClick={wrappedInitiate}>Connect</ActionsButton>;
}

function HideFromDiscoveryButton({ slug, className }: SlugProp): React.JSX.Element {
  const [hideMutation] = useHideDiscoveryResult300Mutation();
  const { data } = useBioV2FromSlugBasicsQuery({
    variables: { slug },
    fetchPolicy: "cache-first",
  });
  const hidden = match(data?.l_bio_v2)
    .with({ __typename: "L_Bio_V2_Success" }, (b) => !!b.user.hidden_from_my_discovery)
    .otherwise(() => false);
  return (
    <ActionsButton
      outline
      className={clsx(className, "btn-neutral")}
      onClickWrapped={() =>
        hideMutation({
          refetchQueries: [GqlOps.Query.BioV2FromSlugBasics],
          variables: { slug, unhide: hidden },
        })
      }
    >
      {hidden ? "Unhide" : "Hide"}
    </ActionsButton>
  );
}

/**
 * Friend or partners should be able to vouch / message / disconnect
 */
function ActionsFriendOrPartnerMutual({
  slug,
  rel,
}: ActionsCommonProps<
  Relationship_Statuses_Enum.Accepted,
  Relationship_Types_Enum.Friend | Relationship_Types_Enum.Partner
>): React.JSX.Element {
  const { pronouns } = useSlugPronouns(slug);
  const { disconnect } = useRelFunctionsWithToast();
  if (!rel) return <></>;
  return (
    <ActionsSection>
      <ActionsButtonsContainer>
        <ActionsAddThreadButton slug={slug} />
        <VouchControl
          size="full"
          slug={slug}
          label={"Vouch for " + (pronouns?.obj ?? "them")}
          labelVouched={match(rel.type)
            .with(Tp.Partner, () => "Partners")
            .with(Tp.Friend, () => "Friends")
            .exhaustive()}
          btnClassName="!py-2 font-semibold"
          outlined={true}
        />
      </ActionsButtonsContainer>
      {/* <button onClick={() => disconnect(slug, rel.type)} className={clsx("link italic text-sm ")}>
        disconnect
      </button> */}
    </ActionsSection>
  );
}

/**
 * This component is used when there is no connection. Allows for Like / Hide / Friend
 */
function ActionsNoConnection({ slug }: ActionsCommonProps<never, never>): React.JSX.Element {
  const { pronouns } = useSlugPronouns(slug);
  const [buttonStages, setButtonStages] = React.useState(ConnectButtonStages.INITIAL);

  return (
    <ActionsSection>
      <ActionsButtonsContainer>
        {/* <HideFromDiscoveryButton className="shrink-1 min-w-0" {...{ slug }} />
        <AddFriendButton className={clsx("flex-2 shrink-0")} {...{ slug, pronouns }} />
        <RequestMatchButton className={clsx("flex-2 shrink-0")} {...{ slug, pronouns }} /> */}
        <AddFriendButton className={clsx("flex-2 shrink-0")} {...{ slug, pronouns }} />
      </ActionsButtonsContainer>
    </ActionsSection>
  );
}

type BioBasicsActionsProps = {
  screenName: string;
  loggedIn: boolean;
  slug: string;
  isMyPage?: boolean;
};
function BioBasicsActionsV2({
  loggedIn,
  slug,
  screenName,
  isMyPage,
}: BioBasicsActionsProps): React.JSX.Element {
  const my_id = useMyId()!;
  const relStatusQuery = useRelationshipStatusBySlugQuery({
    skip: !my_id || isMyPage,
    variables: {
      slug: slug,
      my_id,
    },
  });
  const rel = relStatusQuery?.data?.relationships?.[0];
  const fromMe = rel?.from_user_id === my_id;
  if (relStatusQuery.loading && !relStatusQuery.data?.relationships)
    return (
      <div className={clsx("p-2")}>
        <SpinnerCentered />
      </div>
    );
  if (!loggedIn) return <></>;
  return (
    <div className="BioBasicsActions flex-1 flex flex-row-center-center flex-nowrap space-x-1 text-Simmr-purple-700">
      {isMyPage ? (
        <ActionsForMyPageV2 {...{ slug, screenName }} />
      ) : (
        <ActionsRouter {...{ screenName, rel, fromMe, slug }} />
      )}
    </div>
  );
}

function BioBasicsActionsV1({
  loggedIn,
  slug,
  screenName,
  isMyPage,
}: BioBasicsActionsProps): React.JSX.Element {
  const my_id = useMyId()!;
  const initiateThreadQuery = useCanInitiateThreadQuery({
    skip: isMyPage || !slug,
    variables: { slug: slug! },
  });
  const relStatusQuery = useRelationshipStatusBySlugQuery({
    skip: !my_id || isMyPage,
    variables: {
      status_exp: { _eq: Relationship_Statuses_Enum.Accepted },
      slug: slug,
      my_id,
    },
  });
  const connected = !!initiateThreadQuery?.data?.l_can_initiate_thread;
  return (
    <div className="BioBasicsActions flex-1 flex flex-row justify-center flex-nowrap space-x-1 text-Simmr-purple-700">
      {isMyPage ? (
        <ActionsForMyPageV1 screenName={screenName} />
      ) : connected ? (
        <>
          <BasicsButton>
            <AddThreadButton disabled={!loggedIn || isMyPage} />
          </BasicsButton>
          <BasicsButton>
            <MultipleTypeRelationshipControlButton
              rel_types={[
                Relationship_Types_Enum.Partner,
                Relationship_Types_Enum.Friend,
                Relationship_Types_Enum.Like,
              ]}
              disabled={!loggedIn || isMyPage}
              slug={slug}
            />
          </BasicsButton>
        </>
      ) : (
        <>
          <BasicsButton>
            <NonPartnerRelationshipTypeControl
              rel_type={Relationship_Types_Enum.Like}
              disabled={!loggedIn || isMyPage}
              slug={slug}
              bypassModalConfirmation={true}
            />
          </BasicsButton>
          <BasicsButton>
            <NonPartnerRelationshipTypeControl
              rel_type={Relationship_Types_Enum.Friend}
              disabled={!loggedIn || isMyPage}
              slug={slug}
            />
          </BasicsButton>
        </>
      )}
    </div>
  );
}

function ActionsRouter({
  rel,
  screenName,
  fromMe: fromMeArg,
  slug,
}: {
  fromMe?: boolean;
  screenName: string;
  slug: string;
  rel?: Maybe<RelationshipStatusBySlugQuery["relationships"][0]>;
}): React.JSX.Element {
  const fromMe = !!fromMeArg;

  // const { verified } = useIdentityVerifiedStatus();

  // if (!verified) {
  //   return (
  //     <VerificationBox
  //       heading="🪪 Verify Your Identity 🪪"
  //       text="To maintain trust and safety, all users of Simmr need to verify their profile with government ID before connecting to other members"
  //     />
  //   );
  // }

  return (
    // prettier-ignore
    match({ rel, fromMe })
      .with({ rel: P.nullish }, () => <ActionsNoConnection slug={slug} />)
      .with(
        { rel: { status: St.EndedByToUser } },
        { rel: { status: St.EndedByFromUser } },
        { rel: { status: St.EndedByToUser } },
        { rel: { status: St.EndedByFromUser } },
        { rel: { status: St.Rejected } },
        () => <ActionsNoConnection slug={slug} />
      )
      .with({ rel: { type: Tp.Friend, status: St.Accepted } },                 ({ rel }) => <ActionsFriendOrPartnerMutual {...{ slug, rel, fromMe }} />)
      .with({ rel: { type: Tp.Friend, status: St.Requested }, fromMe: true },  ({ rel }) => <ActionsFriendSent {...{ slug, rel, fromMe }} />)
      .with({ rel: { type: Tp.Friend, status: St.Requested }, fromMe: false }, ({ rel }) => <ActionsFriendReceived {...{ screenName, slug, rel, fromMe }} />)
      .with({ rel: { type: Tp.Like, status: St.Accepted } },                 ({ rel }) => <ActionsLikeMutual {...{ slug, rel, fromMe }} />)
      .with({ rel: { type: Tp.Like, status: St.Requested }, fromMe: true },  ({ rel }) => <ActionsLikeSent {...{ slug, rel, fromMe }} />)
      .with({ rel: { type: Tp.Like, status: St.Requested }, fromMe: false }, ({ rel }) => <ActionsLikeReceived {...{ slug, rel, fromMe }} />)
      .with({ rel: { type: Tp.Partner, status: St.Accepted } }, ({ rel }) => <ActionsFriendOrPartnerMutual {...{ slug, rel, fromMe }} />)
      .with({ rel: { type: Tp.Partner, status: St.Requested } }, () => <div>unexpected partner state</div>)
      .with({ rel: { type: Tp.Ghost, status: St.Accepted } },                 ({ rel }) => <></>)
      .with({ rel: { type: Tp.Ghost, status: St.Requested }, fromMe: true },  ({ rel }) => <></>)
      .with({ rel: { type: Tp.Ghost, status: St.Requested }, fromMe: false }, ({ rel }) => <></>)
      .exhaustive()
  );
}

// export function BioBasics({
export function BioBasicsV2({
  editable,
  slug: currentPageSlug,
  age,
  gender,
  orientation,
  bio,
  screen_name,
  profile_image_id,
  status,
  tagline,
  verified,
  friend_count,
  vouchLevel,
  vouchCount,
  mediaCount,
  partnered,
}: BasicsPropsV2): React.JSX.Element {
  const { isMyPage } = useIsMyPage();
  const slug = useTypedParams(allRoutes.PROFILE).slug;
  const showStackNav = useShouldShowNavStackHeaderState();
  const my_id = useMyId();
  const loggedIn = !!my_id;
  const location = useLocation();
  const navigate = useNavigate();
  const initiateThreadQuery = useCanInitiateThreadQuery({
    skip: isMyPage || !slug,
    variables: { slug: slug! },
  });
  const connected = !!initiateThreadQuery?.data?.l_can_initiate_thread;
  const { data, loading, refetch } = useUserNewInAppNotifications510Query({
    variables: { user_id: my_id! },
    skip: !loggedIn || !isMyPage,
  });

  const { showVouchPopup, fromSlug, fromScreenName, notificationId } = location.state || {};
  const { slug: mySlug } = useMyInfo();
  const { url, thumbHash } = useProfileImageUrl(currentPageSlug, { imageId: profile_image_id });
  let new_notification = data?.in_app_notifications?.[0];
  const notificationIdFromData = new_notification?.id;
  const slugFromData = new_notification?.from_user_summary?.slug;
  const screenNameFromData = new_notification?.from_user_summary?.screen_name;
  const newLevelAchieved = isVouchLevelAchieved(vouchCount, vouchLevel!);
  const [hasNavigated, setHasNavigated] = React.useState(false);
  const [popupClosed, setPopupClosed] = React.useState(() => {
    const closedPopups = JSON.parse(localStorage.getItem("closedVouchPopups") || "{}");
    return closedPopups[notificationIdFromData || notificationId];
  });

  const shouldShowNewVouchLevelModal = React.useMemo(
    () =>
      !loading &&
      new_notification &&
      notificationIdFromData &&
      slugFromData &&
      screenNameFromData &&
      (vouchCount === 1 || newLevelAchieved),
    [
      loading,
      new_notification,
      notificationIdFromData,
      slugFromData,
      screenNameFromData,
      vouchCount,
      newLevelAchieved,
    ]
  );

  const shouldShowNewVouchReceivedPopup = React.useMemo(
    () =>
      !popupClosed &&
      (showVouchPopup ||
        (!shouldShowNewVouchLevelModal &&
          (new_notification || notificationId) &&
          (slugFromData || fromSlug) &&
          (screenNameFromData || fromScreenName))),
    [
      popupClosed,
      showVouchPopup,
      shouldShowNewVouchLevelModal,
      new_notification,
      notificationId,
      slugFromData,
      fromSlug,
      screenNameFromData,
      fromScreenName,
    ]
  );

  React.useEffect(() => {
    if (shouldShowNewVouchReceivedPopup && !hasNavigated && !popupClosed) {
      const targetSlug = fromSlug || slugFromData;
      const targetScreenName = fromScreenName || screenNameFromData;
      const targetNotificationId = notificationId || notificationIdFromData;

      if (targetSlug && targetSlug !== slug && targetSlug !== mySlug) {
        setHasNavigated(true);
        navigate(allRoutes.PROFILE.buildPath({ slug: targetSlug }), {
          replace: true,
          state: {
            showVouchPopup: true,
            fromSlug: targetSlug,
            fromScreenName: targetScreenName || "",
            notificationId: targetNotificationId || "",
          },
        });
      }
    }
  }, [
    shouldShowNewVouchReceivedPopup,
    hasNavigated,
    popupClosed,
    fromSlug,
    slugFromData,
    fromScreenName,
    screenNameFromData,
    notificationId,
    notificationIdFromData,
    slug,
    mySlug,
    navigate,
  ]);
  const [showNotConnectedModal, setShowNotConnectedModal] = React.useState(false);

  const handleClosePopup = React.useCallback(async () => {
    setPopupClosed(true);
    const closedPopups = JSON.parse(localStorage.getItem("closedVouchPopups") || "{}");
    closedPopups[notificationId || notificationIdFromData] = true;
    localStorage.setItem("closedVouchPopups", JSON.stringify(closedPopups));
    refetch();
    setHasNavigated(false);
  }, [notificationId, notificationIdFromData, navigate, mySlug, refetch]);

  React.useEffect(() => {
    // Refetch notifications when navigating back to the page
    if (!loading && hasNavigated) {
      refetch();
    }
  }, [hasNavigated, refetch, loading]);
  return (
    <div className="">
      {shouldShowNewVouchLevelModal &&
        notificationIdFromData &&
        slugFromData &&
        screenNameFromData && (
          <NewVouchLevelModal
            first_vouch={vouchCount === 1}
            vouchLevel={vouchLevel ?? undefined}
            newLevelAchieved={newLevelAchieved}
            notificationId={notificationIdFromData}
            fromSlug={slugFromData}
            fromScreenName={screenNameFromData}
            refetchVouchNotifications={refetch}
            mySlug={mySlug}
          />
        )}
      {shouldShowNewVouchReceivedPopup &&
        (fromSlug || slugFromData) &&
        (fromScreenName || screenNameFromData) &&
        (notificationId || notificationIdFromData) && (
          <NewVouchReceivedPopup
            fromSlug={(fromSlug || slugFromData || "").toString()}
            fromScreenName={(fromScreenName || screenNameFromData || "").toString()}
            notificationId={(notificationId || notificationIdFromData || "").toString()}
            onClose={handleClosePopup}
          />
        )}
      <div
        className={`BioBasicsV2 flex-col overflow-x-hidden flex justify-center items-center gap-4`}
      >
        <div className="relative bg-gray-200">
          {connected && (
            <div className="absolute left-8 top-4 flex flex-col gap-2">
              <h3 className="text-md px-2 rounded-full bg-[#E1E1E1] bg-opacity-50 font-bold leading-relaxed">
                Connected
              </h3>
            </div>
          )}
          <LazyImage src={url} thumbHash={thumbHash} className="!px-0" />
          <div className="absolute right-4 top-4 flex flex-col gap-2">
            {vouchLevel && (
              <p className="flex justify-center  items-center rounded-full font-semibold bg-[#E1E1E1] bg-opacity-50 leading-relaxed pr-4">
                <span className="p-1 mr-1 rounded-full bg-white">
                  <VouchSimpleInfo iconOnly level={vouchLevel} />
                </span>{" "}
                {vouchLevel.charAt(0).toUpperCase() + vouchLevel.slice(1)}
              </p>
            )}
          </div>
          <div className="absolute left-8 bottom-4 flex flex-col gap-2">
            <div className="flex gap-2">
              <h3 className="text-2xl px-2 rounded-full bg-[#E1E1E1] bg-opacity-50 font-bold leading-relaxed">
                {screen_name} {age && `(${age})`}
              </h3>
              <h3 className="text-2xl">
                {verified && <VerifiedIdentityIcon className="text-blue-500" />}
              </h3>
            </div>
            <div className="flex gap-2">
              <h3 className="text-md px-2 rounded-full bg-[#E1E1E1] bg-opacity-50 font-bold leading-relaxed">
                {gender}
              </h3>
              {/* <h3 className="text-md px-2 rounded-full bg-[#E1E1E1] bg-opacity-50 font-bold leading-relaxed">
                {orientation && orientation?.charAt(0).toUpperCase() + orientation?.slice(1)}
              </h3> */}
              <h3 className="text-md px-2 rounded-full bg-[#E1E1E1] bg-opacity-50 font-bold leading-relaxed">
                {partnered ? "Partnered" : "Single"}
              </h3>
            </div>
          </div>
        </div>
        <div className="px-8 flex flex-col gap-2 w-full">
          {/* {match(buttonStages)
            .with(ConnectButtonStages.INITIAL, () => (
              <Button
                onClick={async () => {
                  setButtonStages(ConnectButtonStages.MATCH_OR_FRIEND);
                }}
                variant="standard"
              >
                Connect
              </Button>
            ))
            .with(ConnectButtonStages.MATCH_OR_FRIEND, () => (
              <>
                <Button
                  onClick={async () => {
                    setButtonStages(ConnectButtonStages.MESSAGE);
                  }}
                  variant="standard"
                >
                  <FontAwesomeIcon icon={faHeart} /> Match
                </Button>
                <Button
                  onClick={async () => {
                    setButtonStages(ConnectButtonStages.MESSAGE);
                  }}
                  variant="neutral"
                >
                  <FontAwesomeIcon icon={faUserPlus} /> Add as a Friend
                </Button>
              </>
            ))
            .with(ConnectButtonStages.MESSAGE, () => (
              <>
                <div className="flex gap-2">
                  <Button className="flex-2" onClick={() => null} variant="neutral">
                    <FontAwesomeIcon icon={faUserPlus} /> Add as a Friend
                  </Button>
                  <Button className="flex-1" onClick={() => null} variant="standard">
                    <FontAwesomeIcon icon={faHeart} /> Match
                  </Button>
                </div>
                <Button className="flex-2" onClick={() => null} variant="primary">
                  Message
                </Button>
              </>
            ))
            .exhaustive()} */}
          <BioBasicsActionsV2
            {...{
              isMyPage,
              screenName: screen_name,
              slug: currentPageSlug,
              loggedIn,
            }}
          />

          <p className="text-md text-white">{tagline}</p>
          <div className="flex px-5 text-center w-full justify-between">
            <div className="flex flex-col items-center">
              <Text as="p" size="xxs" className="py-1">
                {mediaCount}
              </Text>
              <Text as="p" size="xxs" className="text-gray-400">
                Posts
              </Text>
            </div>
            {connected && (
              <Link
                to={allRoutes.PROFILE.FRIENDS.buildPath({ slug: currentPageSlug })}
                className="flex flex-col items-center"
                aria-disabled={connected}
              >
                <Text as="p" size="xxs" className="py-1">
                  {friend_count}
                </Text>
                <Text as="p" size="xxs" className="text-gray-400">
                  Connections
                </Text>
              </Link>
            )}
            {!connected && (
              <div
                className="flex flex-col items-center"
                onClick={() => setShowNotConnectedModal(true)}
              >
                <Text as="p" size="xxs" className="py-1">
                  {friend_count}
                </Text>
                <Text as="p" size="xxs" className="text-gray-400">
                  Connections
                </Text>
              </div>
            )}
            {showNotConnectedModal && (
              <BigModal onDismiss={() => setShowNotConnectedModal(false)} className="w-full">
                <div className="flex flex-col items-center justify-center p-8 bg-[#2A2C38] rounded-3xl shadow-2xl max-w-sm mx-auto">
                  <Text as="p" size="xxs" className="text-gray-400 py-5">
                    Connect with {screen_name} to see their connections
                  </Text>
                  <AddFriendButton slug={currentPageSlug} className="mt-4" pronouns={null} />
                  <Button
                    onClick={() => setShowNotConnectedModal(false)}
                    variant="outline"
                    className="mt-4"
                  >
                    Cancel
                  </Button>
                </div>
              </BigModal>
            )}
            {/* {slug && (
              <Link
                to={allRoutes.PROFILE.VOUCHES.buildPath({ slug })}
                className="flex flex-col items-center"
              >
                <Text as="p" size="xxs" className="py-1">
                  {vouchCount}
                </Text>
                <Text as="p" size="xxs" className="text-gray-400">
                  Vouches
                </Text>
              </Link>
            )} */}
            {slug && (
              <div className="flex flex-col items-center">
                <Text as="p" size="xxs" className="py-1">
                  {vouchCount}
                </Text>
                <Text as="p" size="xxs" className="text-gray-400">
                  Vouches
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className="px-10 w-full">
          <div className="w-full border-t border-white py-2" />
        </div>
        {/* <div className="lg:pl-4 max-lg:text-center flex-1 max-lg:space-y-1 max-lg:pt-2 flex flex-col min-w-0">
          <div className={clsx("flex flex-row justify-center lg:justify-start")}>
            {vouchLevel && (
              <>
                <VouchSimpleInfo level={vouchLevel} /> <span className="px-2">|</span>
              </>
            )}
            {slug && loggedIn ? (
              <Link to={allRoutes.PROFILE.FRIENDS.buildPath({ slug })} className={clsx("link")}>
                {friend_count} {friend_count === 1 ? "friend" : "friends"}
              </Link>
            ) : (
              <span>
                {friend_count} {friend_count === 1 ? "friend" : "friends"}
              </span>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-1 max-lg:pt-2 max-lg:pb-2 max-lg:w-full text-center justify-between items-center">
          <BioBasicsActionsV2
            {...{
              isMyPage,
              screenName: screen_name,
              slug: currentPageSlug,
              loggedIn,
            }}
          />
        </div> */}
      </div>
    </div>
  );
}

export function BioBasicsV1({
  editable,
  slug: currentPageSlug,
  screen_name,
  profile_image_id,
  verified,
  ...otherProps
}: BasicsProps): React.JSX.Element {
  const { isMyPage } = useIsMyPage();
  const { slug } = useParams();
  const loggedIn = !!useMyId();

  const { status, stat_one_label, stat_one_value, stat_two_label, stat_two_value } = otherProps;
  return (
    <UserPageContainer className="">
      <div
        className={`BioBasicsV1 flex-col overflow-x-hidden lg:flex-row flex justify-center items-center`}
      >
        <div className={` flex-0`}>
          <Avatar
            {...{
              slug: currentPageSlug,
              screen_name,
              image_id: profile_image_id,
            }}
            className="max-lg:w-20 max-lg:h-20"
          />
        </div>
        <div className="lg:pl-4 max-lg:text-center flex-1 max-lg:space-y-2 max-lg:pt-2 flex flex-col min-w-0">
          <h3 className="text-3xl font-bold leading-relaxed">{screen_name}</h3>

          <h4 className="text-2xl font-semibold underline">
            @{currentPageSlug}
            {verified ? <VerifiedIdentityIcon dark /> : null}
          </h4>
          <BasicsStatus {...{ status, editable }} />
        </div>
        <div className="flex flex-row flex-1 max-lg:pt-6 max-lg:pb-2 max-lg:w-full text-center justify-between items-center">
          <div className="flex-1  border-none place-items-center">
            <div className="stat-title text-sm">{stat_one_label}</div>
            <div className="stat-value text-2xl cursor-pointer">
              {slug ? (
                <Link to={allRoutes.PROFILE.buildPath({ slug })}>{stat_one_value}</Link>
              ) : (
                <span>{stat_one_value}</span>
              )}
            </div>
          </div>

          <div className="flex-1 border-none place-items-center ">
            <IndicatorNavLink
              to={allRoutes.PROFILE.FRIENDS.buildPath({
                slug: currentPageSlug,
              })}
            >
              <div className="stat-title text-sm">{stat_two_label}</div>
              <div className="stat-value text-2xl text-candid-purple-700 cursor-pointer">
                {stat_two_value}
              </div>
            </IndicatorNavLink>
          </div>
          <BioBasicsActionsV1
            {...{
              isMyPage,
              screenName: screen_name,
              slug: currentPageSlug,
              loggedIn,
            }}
          />
        </div>
      </div>
    </UserPageContainer>
  );
}
