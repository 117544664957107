import {
  DiscoveryResults542Query,
  Discovery_Success_542Fragment,
  Feature_Flags_Enum,
  GqlOps,
  L_Discovery_Response_Missing_Fields,
  L_Vouch_Level,
  Relationship_Statuses_Enum,
  Relationship_Types_Enum,
  useDiscoveryResults542Query,
  useHideDiscoveryResult300Mutation,
} from "shared/dist/__generated__/components";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { Maybe, classNames, filterNulls, typedFromEntries, uniqWith } from "shared/dist/util";
import { P, match } from "ts-pattern";
import { ProfileList, ProfileListItem } from "../../../widgets/profile-list";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { faCirclesOverlap, faHeart, faUser } from "@fortawesome/pro-light-svg-icons";
import { faSliders, faWarning, faXmark } from "@fortawesome/pro-solid-svg-icons";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Container } from "../../../widgets/container";
import { DatingSettings } from "../settings/dating/dating-main";
import { ErrorBoundary } from "react-error-boundary";
import { LazyImage } from "shared-web-react/dist/widgets/lazy-image";
import { LazyMediaItem } from "../../../widgets/lazy-media-item";
import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import React, { useEffect, useState } from "react";
import { SearchBar } from "../../../widgets/search-bar";
import { VouchSimpleInfo } from "../vouching/vouch-info-box";
import { SimmerZeroScreen } from "../../../widgets/zero-screens";
import mixpanel from "mixpanel-browser";
import { useOptimalImageSize } from "../../../util/optimized-image-sizes";
import { useProfileImageUrl } from "shared-web-react/dist/util/url-helpers";
import { useGenderInfo } from "shared-web-react/dist/helpers/gender";
import { allRoutes } from "../../../util/routes";
import { useLeftMargin } from "../main-nav";
import { useIsProd } from "shared/dist/util/env";
import { useMyInfo } from "../../bio/bio-settings";
import { useMyId } from "shared/dist/auth-data";
import { useDebounce } from "use-debounce";
import { useExtendedFriendList } from "../../../widgets/use-extended-friends-list";
import { useVirtualizer } from "@tanstack/react-virtual";

// Import discovery zero images
import discovery_zero_image_01 from "../../../assets/discovery_zero_image_1.jpg";
import discovery_zero_image_02 from "../../../assets/discovery_zero_image_2.jpg";
import discovery_zero_image_03 from "../../../assets/discovery_zero_image_3.jpg";
import discovery_zero_image_04 from "../../../assets/discovery_zero_image_4.jpg";
import discovery_zero_image_05 from "../../../assets/discovery_zero_image_5.jpg";
import discovery_zero_image_06 from "../../../assets/discovery_zero_image_6.jpg";
import discovery_zero_image_07 from "../../../assets/discovery_zero_image_7.jpg";
import discovery_zero_image_08 from "../../../assets/discovery_zero_image_8.jpg";
import discovery_zero_image_09 from "../../../assets/discovery_zero_image_9.jpg";
import discovery_zero_image_10 from "../../../assets/discovery_zero_image_10.jpg";
import discovery_zero_image_11 from "../../../assets/discovery_zero_image_11.jpg";
import discovery_zero_image_12 from "../../../assets/discovery_zero_image_12.jpg";
import { VisibilityObserver } from "shared-web-react/dist/widgets/visibility-observer";
import clsx from "clsx";

const imageLoadCache = new Map<string, boolean>();
const IMAGE_CACHE_CLEANUP_INTERVAL = 2 * 60 * 1000; // 2 minutes
const MAX_CACHE_SIZE = 150; // Limit cache size to prevent memory issues

function cleanupImageCache() {
  if (imageLoadCache.size > MAX_CACHE_SIZE) {
    // Remove oldest entries when cache gets too large
    const keysToDelete = Array.from(imageLoadCache.keys()).slice(
      0,
      Math.floor(MAX_CACHE_SIZE * 0.3)
    ); // Remove 30% of entries
    keysToDelete.forEach((key) => imageLoadCache.delete(key));
  }
}

let cleanupInterval: number | null = null;

function initCleanupInterval() {
  if (cleanupInterval === null) {
    cleanupInterval = window.setInterval(cleanupImageCache, IMAGE_CACHE_CLEANUP_INTERVAL);
  }
}

function clearCleanupInterval() {
  if (cleanupInterval !== null) {
    window.clearInterval(cleanupInterval);
    cleanupInterval = null;
  }
}

function useOptimizedImageLoading(imageUrl: string | undefined): "eager" | "lazy" {
  // Default to lazy loading if no URL provided
  if (!imageUrl) return "lazy";

  const [isLoaded, setIsLoaded] = React.useState<boolean>(imageLoadCache.has(imageUrl) || false);

  useEffect(() => {
    if (!imageUrl) {
      setIsLoaded(false);
      return;
    }

    // If already in cache, use cached value
    if (imageLoadCache.has(imageUrl)) {
      setIsLoaded(imageLoadCache.get(imageUrl) || false);
      return;
    }

    // Prevent loading too many images at once
    if (document.querySelectorAll('img[loading="eager"]').length > 10) {
      return; // Skip eager loading if too many images are already loading
    }

    const img = new Image();
    let isMounted = true;

    img.onload = () => {
      if (!isMounted) return;
      imageLoadCache.set(imageUrl, true);
      setIsLoaded(true);
    };

    img.onerror = () => {
      if (!isMounted) return;
      imageLoadCache.set(imageUrl, false);
      setIsLoaded(false);
    };

    img.src = imageUrl;

    return () => {
      isMounted = false;
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl]);

  return isLoaded ? "eager" : "lazy";
}

function ProfileGridButtonContent({ icon }: Pick<FontAwesomeIconProps, "icon">): React.JSX.Element {
  return (
    <span
      className={classNames(
        "inline-flex font-bold justify-center items-center border-2 border-primary rounded-full bg-primary-content text-primary w-10 h-10"
      )}
    >
      <FontAwesomeIcon icon={icon} className="" />
    </span>
  );
}

type ProfileGridDummyDataProps = {
  isFake: true;
  fakeId: string;
  imgUrl: string;
};

function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const zeroScreenData = [
  { gender_id: "gender_woman", screenName: "Jordan", image: discovery_zero_image_01 },
  { gender_id: "gender_woman", screenName: "Taylor", image: discovery_zero_image_02 },
  { gender_id: "gender_woman", screenName: "Casey", image: discovery_zero_image_03 },
  { gender_id: "gender_woman", screenName: "Alex", image: discovery_zero_image_04 },
  { gender_id: "gender_woman", screenName: "Morgan", image: discovery_zero_image_05 },
  { gender_id: "gender_woman", screenName: "Sam", image: discovery_zero_image_06 },
  { gender_id: "gender_woman", screenName: "Jamie", image: discovery_zero_image_07 },
  { gender_id: "gender_woman", screenName: "Cameron", image: discovery_zero_image_08 },
  { gender_id: "gender_woman", screenName: "Avery", image: discovery_zero_image_09 },
  { gender_id: "gender_woman", screenName: "Riley", image: discovery_zero_image_10 },
  { gender_id: "gender_woman", screenName: "Quinn", image: discovery_zero_image_11 },
  { gender_id: "gender_woman", screenName: "Peter", image: discovery_zero_image_12 },
] as const;
export const useZeroScreenDummyProfiles = () => {
  return zeroScreenData.map((data) => ({
    distance_m: getRandomNumber(1609, 50000),
    age: getRandomNumber(21, 50),
    ...data,
  }));
};

function GridGenderPill({ label }: { label: undefined | string }): null | React.JSX.Element {
  if (!label) return null;
  return (
    <div
      style={{ flexShrink: 1 }}
      className={classNames(
        "min-w-0 flex-grow-0 overflow-hidden lowercase whitespace-nowrap py-[1px] px-[4px] rounded-sm text-xs bg-secondary text-secondary-content text-ellipsis"
      )}
    >
      {label}
    </div>
  );
}

type ProfileGridRealDataProps = { id: string };
type ProfileGridItemProps = {
  age: number;
  distance_m: Maybe<number>;
  gender_id: string;
  limitations: Discovery_Success_542Fragment["limitations"];
  onBlock?: () => void;
  profileGridPageNumber?: number;
  relationships_to_user: Array<{ type: string; status: string }>;
  threads_with_user: Array<string>;
  user_screen_name: string;
  user_slug: string;
  verified: boolean;
  vouchLevel: L_Vouch_Level | null | undefined;
  partner: Maybe<{
    user_slug: string;
    user_screen_name: string;
    gender_id: string;
  }>;
} & (ProfileGridDummyDataProps | ProfileGridRealDataProps);

export function ProfileGridItem({
  age,
  distance_m,
  gender_id,
  limitations,
  onBlock,
  partner,
  profileGridPageNumber,
  relationships_to_user,
  threads_with_user,
  user_screen_name,
  user_slug,
  verified,
  vouchLevel,
  ...props
}: ProfileGridItemProps): React.JSX.Element {
  const [hideMutation, { loading }] = useHideDiscoveryResult300Mutation();
  const isFake = "isFake" in props && props.isFake;
  const genderData = useGenderInfo(gender_id);
  const [isFading, setIsFading] = React.useState(false);
  const partnerGenderData = useGenderInfo(partner?.gender_id);
  const profileImageUrl = isFake ? { url: props.imgUrl } : useProfileImageUrl(user_slug);

  // Add error state tracking for image loading
  const [imageLoadError, setImageLoadError] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(true);

  // Determine loading strategy for the image
  const loadingStrategy = useOptimizedImageLoading(profileImageUrl?.url);

  const navigate = useNavigate();
  const matchId = match(props)
    .with({ id: P.select() }, (i) => i)
    .otherwise(() => null);
  const vouchColor = match(vouchLevel)
    .with(L_Vouch_Level.HighlyRecommended, () => "#F6CF51")
    .with(L_Vouch_Level.Recommended, () => "#DFDFDF")
    .with(L_Vouch_Level.TopUser, () => "#F6CF51")
    .otherwise(() => "");
  try {
    return (
      <ErrorBoundary
        FallbackComponent={() => (
          <div className="ProfileGridItem relative w-full h-auto object-cover aspect-square rounded-lg overflow-hidden bg-gray-700">
            <div className="absolute inset-0 flex items-center justify-center text-white text-sm">
              Failed to load profile
            </div>
          </div>
        )}
      >
        <div
          id={`disc_${user_slug}`}
          className={classNames(
            "ProfileGridItem relative w-full h-auto object-cover aspect-square",
            "rounded-lg overflow-hidden",
            isFading && "transition-opacity duration-500 opacity-0",
            vouchLevel && `border-4 ${vouchColor ? `border-[${vouchColor}]` : ""}`
          )}
          onClick={() => {
            mixpanel.track("User visited profile from discovery", {
              visitedProfileSlug: user_slug,
            });
            navigate(allRoutes.PROFILE.buildPath({ slug: user_slug }));
          }}
          onTransitionEnd={() => {
            if (!isFading) return;
            onBlock?.();
            hideMutation({
              variables: { slug: user_slug, partner_slug: partner?.user_slug, unhide: false },
              refetchQueries: [GqlOps.Query.DiscoveryResults300, GqlOps.Query.NewsFeed440],
              update: async (cache) => {
                const cacheId = cache.identify({
                  __typename: "L_Discovery_Item",
                  id: matchId,
                });
                cache.evict({ broadcast: true, id: cacheId });
              },
            });
          }}
        >
          <div
            className={`absolute inset-0 w-full h-full bg-cover bg-center bg-gray-200 ${
              isFake ? `blur-[8px]` : ""
            }`}
          >
            {"mediaUploadId" in profileImageUrl && profileImageUrl.mediaUploadId ? (
              <LazyMediaItem
                mediaUploadId={profileImageUrl.mediaUploadId}
                size={useOptimalImageSize("PROFILE_GRID")}
                alt={user_screen_name}
                className="w-full h-full object-cover aspect-square"
                overrideVisibility={true}
                thumbHash={profileImageUrl.thumbHash}
              />
            ) : (
              <LazyImage
                src={profileImageUrl.url}
                alt={user_screen_name}
                overrideVisibility
                className="w-full h-full object-cover aspect-square"
                loading={loadingStrategy}
                onError={() => setImageLoadError(true)}
                onLoad={() => setImageLoading(false)}
              />
            )}
          </div>
          {vouchLevel && (
            <div
              className={`absolute top-0 right-0 bg-[#2A2C38] rounded-full ${
                vouchColor ? `border-2 border-[${vouchColor}]` : ""
              }`}
            >
              <VouchSimpleInfo level={vouchLevel} iconOnly={true} />
            </div>
          )}
          {imageLoadError && (
            <div className="absolute inset-0 flex items-center justify-center text-white text-sm">
              Failed to load image
            </div>
          )}
        </div>
      </ErrorBoundary>
    );
  } catch (err) {
    console.error("🚀 ~ file: profile-grid.tsx:296 ~ err:", err);
    return <></>;
  }
}

function DiscoveryGridFilters({
  disabled,
  onClose,
}: {
  disabled?: boolean;
  onClose: () => void;
}): React.JSX.Element {
  // const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDialogElement>(null);
  // console.log("🚀 - file: profile-grid.tsx:315 - ref:", ref?.current?.open);
  const toggleFilters = React.useCallback(async () => {
    if (disabled) return;
    const isOpen = !!ref?.current?.open;
    console.log("🚀 - file: profile-grid.tsx:319 - toggleFilters - isOpen:", isOpen);
    isOpen ? ref?.current?.close?.() : ref?.current?.showModal?.();
    if (isOpen) onClose();
  }, [disabled, ref]);
  return (
    <>
      <button
        onClick={toggleFilters}
        disabled={disabled}
        className="text-white max-w-full flex flex-row flex-nowrap items-center justify-start overflow-x-hidden"
      >
        <div
          className={classNames(
            "flex-0 rounded-xl p-1 bg-[#69696966] w-12 h-8 text-center aspect-square"
          )}
        >
          {disabled ? <Spinner /> : <FontAwesomeIcon icon={faSliders} fixedWidth />}
        </div>
        <div
          className={classNames(
            "flex-1 min-w-0 ml-2 whitespace-nowrap text-ellipsis overflow-hidden"
          )}
        >
          {/* <DatingPreferencesSummary /> */}
        </div>
      </button>
      <PortalDialog
        ref={ref}
        onCancel={toggleFilters}
        modalBoxClassName="p-0 bg-[#2A2C38] text-white"
      >
        <div className={"px-6"}>
          <DatingSettings />
        </div>
        <button className="btn-neutral btn-circle absolute top-2 right-2" onClick={toggleFilters}>
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </button>
      </PortalDialog>
    </>
  );
}

function DiscoveryError({ explanation }: { explanation: string }): React.JSX.Element {
  return (
    <DiscoveryModal color="error">
      <FontAwesomeIcon icon={faWarning} size="6x" />
      <p className={classNames("text-2xl font-semibold")}>!</p>
      <p className={classNames("text-lg")}>
        But we seem to having some errors. We are looking into it and apologize.
      </p>
      <p>{explanation}</p>
    </DiscoveryModal>
  );
}
function DiscoveryEmpty({}: {}): React.JSX.Element {
  const navigate = useNavigate();

  return (
    <DiscoveryModal color="#464752">
      <SimmerZeroScreen
        header="Your Next Great Connection is Out There!"
        text="We didn’t find a match within your current preferences, but expanding your search could unlock new connections!"
        clickHandler={() => {
          mixpanel.track("User visited discovery settings because they did not have any matches");
          navigate(allRoutes.SETTINGS.DATING.buildPath({}));
        }}
        btnText="Dating Preferences"
      />
    </DiscoveryModal>
  );
}

function DiscoveryComingSoon({}: {}): React.JSX.Element {
  return (
    <DiscoveryModal color="neutral">
      <p className={classNames("text-2xl font-semibold")}>Discovery is Awesome!</p>
      <p className={classNames("text-lg")}>
        We are slowly inviting our beloved users into discovery to ensure the best possible
        experience. We'll let you know when we're ready for you.
      </p>
    </DiscoveryModal>
  );
}

function DiscoveryModal({
  children,
  color,
}: React.PropsWithChildren<{
  color: "secondary" | "warning" | "error" | "neutral" | "accent" | "#464752";
}>): React.JSX.Element {
  const marginLeft = useLeftMargin();
  return (
    <div className={classNames("relative z- max-h-screen overflow-hidden p-2")}>
      <DiscoveryGridDummyData />
      <div
        className={classNames(
          "fixed top-0 bottom-0  left-0 right-0 flex items-center justify-center p-6"
        )}
        style={{ marginLeft }}
      >
        <div
          className={classNames(
            "rounded-lg  bg-[#464752] opacity-95 text-${color}-content shadow-md w-full  max-w-3xl max-h-full overflow-y-auto overflow-x-hidden flex flex-col items-center justify-center gap-2 p-6"
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

const MissingFields = L_Discovery_Response_Missing_Fields;
function DiscoveryNotReadyReason({
  field,
}: {
  field: L_Discovery_Response_Missing_Fields;
}): React.JSX.Element {
  const [reason, link] = React.useMemo(
    () =>
      match(field)
        .returnType<[string, string]>()
        .with(MissingFields.Birthday, () => ["Birthday", allRoutes.SETTINGS.PERSONAL.buildPath({})])
        .with(MissingFields.Gender, () => ["Gender", allRoutes.SETTINGS.PROFILE.buildPath({})])
        .with(MissingFields.Location, () => ["Location", allRoutes.SETTINGS.PROFILE.buildPath({})])
        .with(MissingFields.DiscoveryPreferences, () => [
          "Dating Preferences",
          allRoutes.SETTINGS.DATING.buildPath({}),
        ])
        .exhaustive(),
    [field]
  );
  return (
    <Link className="link" to={link}>
      {reason}
    </Link>
  );
}

function DiscoveryNotReady({
  fields,
}: {
  fields: Array<L_Discovery_Response_Missing_Fields>;
}): React.JSX.Element {
  const navigate = useNavigate();
  return (
    <DiscoveryModal color="#464752">
      <SimmerZeroScreen
        header="Discovery is Awesome!"
        text={`We need a bit more information from your before you can use Discovery, please set the following: ${fields}`}
        clickHandler={() => navigate(allRoutes.SETTINGS.DATING.buildPath({}))}
        btnText="Set Preferences"
      />
      {/* <p className={classNames("text-2xl font-semibold")}>Discovery is Awesome!</p>
      <p className={classNames("text-lg")}>
        Unfortunately your profile isn't ready to partake in this amazing goodness.
      </p>
      <p className={classNames("text-lg")}>You'll have to set the following to be ready:</p>
      <ul className={classNames("list-disc w-full list-outside ml-8")}>
        {fields.map((field) => (
          <li key={field}>
            <DiscoveryNotReadyReason field={field} />
          </li>
        ))}
      </ul> */}
    </DiscoveryModal>
  );
}

export function DiscoveryMain({
  override,
}: {
  override?: "empty" | "not_ready" | "error" | "dummy_data";
}): React.JSX.Element {
  const isProd = useIsProd();
  const navigate = useNavigate();
  const { slug } = useMyInfo();
  const my_id = useMyId();
  const [page, setPage] = React.useState<number>(window.history.state.profileGridPageNumber ?? 0);
  const [fetchedAllResults, setFetchedAllResults] = React.useState(false);
  const [loadedItems, setLoadedItems] = React.useState<
    Array<Discovery_Success_542Fragment["items"][0]>
  >([]);
  const [stopFetching, setStopFetching] = React.useState(false);
  const [itemPageNumbers, setItemPageNumbers] = React.useState<Record<string, number>>({});
  const [searchTerm, setSearchTerm] = React.useState("");
  // const extendedFriendsFeatureFlag = useIsFeatureEnabled(
  //   Feature_Flags_Enum.ExtendedFriendSearchV00
  // );
  const [debouncedFilter, { isPending }] = useDebounce(searchTerm, 1000);

  const [cachedProfiles, setCachedProfiles] = React.useState<ProfileListItem[]>([]);

  const incPage = React.useCallback(() => setPage((page) => page + 1), [setPage]);

  React.useEffect(() => {
    setFetchedAllResults(false);
    setLoadedItems([]);
  }, [override]);

  const { data, loading, fetchMore, refetch, client } = useDiscoveryResults542Query({
    variables: {
      use_dummy_data: !isProd && override === "dummy_data",
      page,
    },
    fetchPolicy: "cache-first",
  });

  const { data: extendedFriendListData, loading: extendedFriendListLoading } =
    useExtendedFriendList({
      slug: slug!,
      userId: my_id!,
      includeRelationships: true,
      filter: debouncedFilter,
    });

  const handleSearch = React.useCallback((term: string) => {
    setSearchTerm(term);
    setLoadedItems([]);
    setPage(0);
    setFetchedAllResults(false);
    if (!term) refetch().then(({ data }) => updateItems(data));
  }, []);

  const updateItems = React.useCallback(
    (innerData: DiscoveryResults542Query) => {
      if (innerData?.l_discovery?.__typename === "L_Discovery_Response_Empty") {
        setStopFetching(true); // Stop fetching if the response is empty
        return;
      }

      if (innerData?.l_discovery?.__typename === "L_Discovery_Response_Success" && !searchTerm) {
        const newItems = innerData.l_discovery.items;
        if (newItems?.length === 0) {
          setFetchedAllResults(true);
          return;
        }
        setItemPageNumbers((current) => ({
          ...current,
          ...typedFromEntries(newItems.map((i) => [i.id, page])),
        }));
        setLoadedItems((currentItems) =>
          uniqWith(filterNulls([...currentItems, ...newItems]), (a, b) => a.id === b.id)
        );
      }
    },
    [page, searchTerm]
  );

  React.useEffect(() => {
    if (!data) return;
    updateItems(data);
  }, [data, fetchedAllResults]);

  const discData = data?.l_discovery;

  const filteredProfiles = React.useMemo(() => {
    if (!extendedFriendListData || !searchTerm) return [];

    const result = extendedFriendListData
      .map((item) => ({
        id: item.id,
        slug: item.user.user_slug,
        name: item.user.user_screen_name,
        screenName: item.user.user_screen_name,
        caption: match(item.user.relationship)
          .with("my_friend", () => (
            <>
              <FontAwesomeIcon icon={faUser} className="mx-1 text-candid-red-300" /> Friend
            </>
          ))
          .with("match", () => (
            <>
              <FontAwesomeIcon icon={faHeart} className="mx-1 text-candid-red-300" /> Match
            </>
          ))
          .with("friends_friend", () => (
            <>
              <FontAwesomeIcon icon={faCirclesOverlap} className="mx-1 text-candid-red-300" />{" "}
              Mutual Friend
            </>
          ))
          .otherwise(() => null),
      }))
      .filter((item) => item.screenName.toLowerCase().startsWith(searchTerm.toLowerCase()));

    if (!isPending()) {
      setCachedProfiles(result);
    }

    return result;
  }, [extendedFriendListData, searchTerm, isPending]);

  const profilesToDisplay = isPending() ? cachedProfiles : filteredProfiles;

  const limitations = discData && "limitations" in discData ? discData.limitations : [];
  if (override && isProd) navigate(allRoutes.DISCOVERY.buildPath({}));
  const filtersDisabled = match({ loading, override, responseType: discData?.__typename })
    .with(
      { override: P.nullish, responseType: "L_Discovery_Response_Success" },
      { override: P.nullish, responseType: "L_Discovery_Response_Empty" },
      () => false
    )
    .otherwise(() => true);

  const scrollParentRef = React.useRef<HTMLDivElement>(null);
  const rowCount = 1 + (loadedItems?.length ?? 0);
  const rowVirtualizer = useVirtualizer({
    count: rowCount,
    getScrollElement: () => scrollParentRef.current,
    estimateSize: () => 330,
    lanes: 2,
    getItemKey: (index) => loadedItems?.[index]?.id ?? "<empty>",
    gap: 8,
    overscan: 16 /* cSpell:disable-line */,
  });
  // const { log: onScreenLogger, clear } = useOnScreenLogger();
  // React.useEffect(() => {
  //   onScreenLogger({
  //     content: `page: ${page} items ${loadedItems?.length}`,
  //     id: "disc_page",
  //   });
  //   return () => clear();
  // }, [page, loadedItems?.length, clear, onScreenLogger]);

  // React.useEffect(() => {
  //   onScreenLogger({
  //     content: `rowcount: ${rowCount} items: ${loadedItems?.length}`,
  //     id: "rowcount",
  //   });
  //   onScreenLogger({ content: "resp: " + discData?.__typename, id: "tn" });
  // }, [rowCount, discData, loadedItems?.length, onScreenLogger]);

  const onAtBottom = React.useCallback(
    async (atBottom: boolean) => {
      if (
        !atBottom || // Only proceed if we're actually at the bottom
        filtersDisabled ||
        fetchedAllResults ||
        loading ||
        searchTerm !== "" ||
        stopFetching // Stop fetching if `stopFetching` is true
      ) {
        return;
      }

      try {
        await fetchMore({
          variables: {
            use_dummy_data: !isProd && override === "dummy_data",
            page: page + 1,
          },
        });
        incPage();
      } catch (error) {
        console.error("Error fetching more items:", error);
      }
    },
    [
      fetchedAllResults,
      loading,
      fetchMore,
      page,
      incPage,
      filtersDisabled,
      searchTerm,
      isProd,
      override,
      stopFetching,
    ]
  );

  // React.useEffect(() => {
  //   if (!loadedItems || loadedItems.length === 0) return;
  //   const elementId = window.history.state?.matchId;
  //   if (!elementId) return;
  //   const index = loadedItems.findIndex((i) => i.id === elementId);
  //   if (elementId) rowVirtualizer.scrollToIndex(index);
  //   window.history.replaceState({}, "");
  // }, [loadedItems, rowVirtualizer]);

  // console.log(loadedItems);
  useEffect(() => {
    initCleanupInterval();
    return clearCleanupInterval;
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <div className="flex flex-col items-center justify-center h-full p-4 text-center">
          <h2 className="text-xl font-bold mb-2">Something went wrong</h2>
          <p className="text-sm mb-4">We're having trouble loading the discovery section</p>
          <p className="text-xs text-gray-500 mb-4">{error?.message}</p>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
            onClick={() => window.location.reload()}
          >
            Reload page
          </button>
        </div>
      )}
    >
      <Container
        size="lg"
        className={classNames(
          "DiscoveryMainContainer h-full flex-col items-stretch justify-start relative overflow-hidden flex"
        )}
      >
        <div className="absolute inset-0 h-full">
          <div
            className={classNames(
              "flex relative items-center justify-between w-full"
              // extendedFriendsFeatureFlag ? "justify-center" : "justify-start"
            )}
          >
            {" "}
            <SearchBar
              onSearch={handleSearch}
              placeholder="Search by Name"
              value={searchTerm}
              loading={isPending()}
            />
            <DiscoveryFiltersWidget
              disabled={filtersDisabled}
              loading={loading}
              onFilterClose={async () => {
                setPage(0);
                setFetchedAllResults(false);
                setLoadedItems([]);
                client.cache.evict({ fieldName: "l_discovery" });
                client.cache.evict({ fieldName: "l_news_feed" });
                refetch().then(({ data }) => updateItems(data));
              }}
            />
          </div>
          <div
            ref={scrollParentRef}
            className="flex-1 relative h-full w-full max-h-full overflow-auto pb-20"
          >
            {searchTerm ? (
              isPending() || (extendedFriendListLoading && profilesToDisplay.length === 0) ? (
                <SpinnerCentered />
              ) : profilesToDisplay.length > 0 ? (
                <ProfileList
                  profiles={profilesToDisplay}
                  mkRoute={(profile) => ({
                    to: allRoutes.PROFILE.buildPath({ slug: profile.slug! }),
                  })}
                  hasBadge={() => false}
                />
              ) : (
                <p>No results available </p>
              )
            ) : (
              <ErrorBoundary
                FallbackComponent={() => (
                  <div className="w-full h-full flex flex-col items-center justify-center p-4 text-center">
                    <div className="text-lg font-bold mb-2">Something went wrong</div>
                    <div className="text-sm mb-4">
                      We're having trouble loading the discovery grid
                    </div>
                    <button
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                      onClick={() => window.location.reload()}
                    >
                      Reload page
                    </button>
                  </div>
                )}
              >
                {match({ loading, override, loadedItems, response: discData })
                  .returnType<React.ReactNode>()
                  .with({ loading: true, loadedItems: [] }, () => <SpinnerCentered />)
                  .with(
                    { response: { __typename: "L_Discovery_Response_Waitlist" } },
                    { response: { __typename: "L_Discovery_Response_Waitlist" } },
                    () => <DiscoveryComingSoon />
                  )
                  .with(
                    { response: { __typename: "L_Discovery_Response_Empty" }, loadedItems: [] },
                    () => <DiscoveryEmpty />
                  )
                  .with({ response: { __typename: "L_Discovery_Response_Error" } }, (e) => (
                    // <DiscoveryError explanation="Oops!" />
                    <DiscoveryError explanation={e.response.human_readable_error ?? ""} />
                  ))
                  .with(
                    { response: { __typename: "L_Discovery_Response_Not_Ready_Yet" } },
                    (missingData) => (
                      <DiscoveryNotReady fields={missingData.response.missing_fields} />
                    )
                  )
                  .with({ loadedItems: P.select() }, (items) => (
                    <div
                      style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: "100%",
                        position: "relative",
                        zIndex: 10,
                        minHeight: "500px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                        }}
                      >
                        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                          const isLeft = virtualRow.index % 2 === 0;
                          const neighborRow =
                            rowVirtualizer.getVirtualItems()[virtualRow.index + (isLeft ? 1 : -1)];
                          const match = items[virtualRow.index];
                          return (
                            <div
                              className={clsx(
                                "py-1",
                                isLeft ? "pr-1 pg-left-side" : "pl-1 pg-right-side",
                                "pg-row-idx-" + virtualRow.index,
                                `pg-start-${virtualRow.start}-neighbor-${
                                  neighborRow?.start ?? "missing"
                                }`
                              )}
                              key={`${virtualRow.key}`}
                              style={{
                                position: "absolute",
                                top: 0,
                                width: "50%",
                                height: `${virtualRow.size}px`,
                                transform: `translateY(${Math.max(virtualRow.start)}px)`,
                                ...(isLeft ? { left: 0 } : { right: 0 }),
                              }}
                            >
                              <div
                                data-index={virtualRow.index}
                                ref={rowVirtualizer.measureElement}
                              >
                                {match && (
                                  <ProfileGridItem
                                    {...match.user}
                                    {...{ id: match.id, verified: !!match.user.verified }}
                                    profileGridPageNumber={itemPageNumbers[match.id]}
                                    onBlock={() =>
                                      setLoadedItems((items) =>
                                        items.filter((i) => i.id !== match.id)
                                      )
                                    }
                                    vouchLevel={match.user.vouch_level}
                                    {...{ partner: match.partner }}
                                    relationships_to_user={match.user.relationships_with_user}
                                    limitations={limitations}
                                  />
                                )}
                              </div>
                              {virtualRow.index === rowCount - 1 && (
                                <VisibilityObserver
                                  onVisibility={onAtBottom}
                                  className="h-2 w-full"
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))
                  .exhaustive()}
              </ErrorBoundary>
            )}
          </div>
        </div>
      </Container>
    </ErrorBoundary>
  );
}

function GridFallback(): React.JSX.Element {
  return (
    <div className="w-full h-full flex-col-center-center bg-primary">
      <Spinner />
    </div>
  );
}

function DiscoveryFiltersWidget({
  disabled,
  loading,
  onFilterClose,
}: {
  loading: boolean;
  disabled: boolean;
  onFilterClose: () => void;
}): React.JSX.Element {
  return (
    <div
      className={classNames(
        "DiscoveryFiltersWidget h-full flex flex-col justify-stretch items-stretch max-h-full overflow-hidden ml-2"
      )}
    >
      <div
        className={classNames(
          "backdrop-blur-sm",
          "flex-0 fit-content flex items-center justify-stretch py-2 "
        )}
      >
        <DiscoveryGridFilters disabled={disabled} onClose={onFilterClose} />
      </div>
    </div>
  );
}

type Matches = Discovery_Success_542Fragment["items"];

function DiscoveryGridDummyData(): React.JSX.Element {
  const dummyProfileData = useZeroScreenDummyProfiles();
  const zeroScreenDummyData: Matches = React.useMemo(
    () =>
      dummyProfileData.map(({ screenName, ...profile }) => ({
        __typename: "L_Discovery_Item",
        id: "",
        user: {
          __typename: "L_Discovery_User",
          threads_with_user: [],
          relationships_with_user: [],
          user_slug: screenName,
          user_screen_name: screenName,
          ...profile,
        },
        partner: undefined,
      })),
    []
  );
  return (
    <div className={classNames("flex-1 my-2 pb-16")}>
      <div className="grid grid-cols-2 gap-2">
        {zeroScreenDummyData.map(
          (match, index) =>
            match.user && (
              <ProfileGridItem
                id={index.toString()}
                key={index.toString()}
                {...match.user}
                {...{ partner: match.partner }}
                threads_with_user={[]}
                relationships_to_user={[]}
                limitations={[]}
                imgUrl={dummyProfileData[index].image}
                verified={Math.random() > 0.5}
                vouchLevel={getRandomVouchLevel()}
              />
            )
        )}
      </div>
    </div>
  );
}

function getRandomVouchLevel(): L_Vouch_Level | null {
  const levels = [
    null,
    L_Vouch_Level.TopUser,
    L_Vouch_Level.Recommended,
    L_Vouch_Level.HighlyRecommended,
  ];
  const randomIndex = Math.floor(Math.random() * levels.length);
  return levels[randomIndex];
}
