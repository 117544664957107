import { ImageUploader, ImageUploaderProps } from "../../widgets/images";
import { ObPageProps, ObScreen } from "./widgets";
import {
  OnboardingImages412Query,
  useOnboardingImages412Query,
} from "shared/dist/__generated__/components";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { classNames, sortBy } from "shared/dist/util";
import { faPlus, faXmark } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UnLazyImage } from "@unlazy/react";
import clsx from "clsx";
import { useIsDev } from "shared/dist/util/env";
import { useMkImgUrl } from "../../util/routes";
import { useMyId } from "shared/dist/auth-data";
import { useOnScreenLogger } from "shared-web-react/dist/util/on-screen-logger";
import { useProfilePageEditFunctions } from "../user-page/edit-functions";
import mixpanel from "mixpanel-browser";

export function ObMedia({
  setDisableButton,
  isReviewScreen = false,
  onNext,
}: {
  setDisableButton: (b: boolean) => void;
  isReviewScreen?: boolean;
  onNext?: () => void;
}): React.JSX.Element {
  const user_id = useMyId();
  const { log } = useOnScreenLogger();
  const { data, refetch } = useOnboardingImages412Query({
    variables: { user_id },
  });
  const isDev = useIsDev();
  const minRequiredImages = 3;

  // Update the button state whenever image count changes
  React.useEffect(() => {
    const { media_uploads } = data ?? {};
    if (isDev) log({ id: "obImages", content: `Requiring ${minRequiredImages} images` });
    setDisableButton(!media_uploads || media_uploads?.length < minRequiredImages);
  }, [data, isDev, log, setDisableButton, minRequiredImages]);

  const my_id = useMyId();
  const sortedImages = React.useMemo(
    () => sortBy(data?.media_uploads ?? [], (e) => new Date(e.created_at).getTime()),
    [data?.media_uploads]
  );
  
  const editFunctions = useProfilePageEditFunctions(my_id, true, refetch);
  
  // Custom delete function that checks image count after deletion
  const handleDeleteImage = React.useCallback(
    async (imageId: string) => {
      if (!editFunctions?.archiveImage) return;
      
      try {
        // Delete the image
        await editFunctions.archiveImage(imageId);
        
        // Refetch to get updated image count
        const result = await refetch();
        const updatedImages = result.data?.media_uploads || [];
        
        // Disable button if below minimum required images
        if (updatedImages.length < minRequiredImages) {
          setDisableButton(true);
        }
        
        console.log(`Image deleted. Current count: ${updatedImages.length}/${minRequiredImages} required`);
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    },
    [editFunctions, refetch, setDisableButton, minRequiredImages]
  );
  
  React.useEffect(() => {
    mixpanel.track("Onboarding Flow: Image upload screen");
  }, []);

  if (isReviewScreen)
    return (
      <div className={classNames("flex flex-col gap-3 items-stretch")}>
        <PendingApprovalImages
          images={sortedImages}
          deleteImage={handleDeleteImage}
          onComplete={() => {
            refetch();
          }}
          onError={() => {
            console.log("🚀 ~ file: thumbnails.tsx ~ line 101 ~ onError");
          }}
        />
      </div>
    );

  return (
    <ObScreen
      title={`Let's add your pictures`}
      subtitle="Please add at least 3 pictures to continue. You can always add more later."
    >
      <div className={classNames("flex flex-col gap-3 h-full w-full items-stretch max-h-60")}>
        <ObImages
          images={sortedImages}
          deleteImage={handleDeleteImage}
          onComplete={() => {
            // First refetch to get the updated image count
            refetch().then((result) => {
              // Get the actual count from the refetched data
              const updatedImages = result.data?.media_uploads || [];
              const currentImageCount = updatedImages.length;
              console.log(`Current image count after upload: ${currentImageCount}`);
              
              // Only enable the button if we have at least 3 images
              const hasEnoughImages = currentImageCount >= minRequiredImages;
              setDisableButton(!hasEnoughImages);
              
              // Refetch again after a delay to ensure UI is updated
              setTimeout(() => {
                console.log("Refetching after delay...");
                refetch();
              }, 1000);
            });
          }}
          onError={() => {
            console.log("🚀 ~ file: thumbnails.tsx ~ line 101 ~ onError");
          }}
        />
        {/* Image counter with improved styling */}
        <div className="mt-4 text-center text-white">
          <p className={sortedImages.length >= minRequiredImages ? "text-green-400" : "text-yellow-400"}>
            {sortedImages.length} of {minRequiredImages} required images uploaded
            {sortedImages.length < minRequiredImages && (
              <span className="font-bold"> ({minRequiredImages - sortedImages.length} more needed)</span>
            )}
          </p>
        </div>
      </div>
    </ObScreen>
  );
}

export function ObImages({
  images,
  deleteImage,
  ...imageUploaderProps
}: {
  deleteImage?: (s: string) => Promise<unknown>;
  images: OnboardingImages412Query["media_uploads"];
} & Pick<ImageUploaderProps, "onComplete" | "onStart" | "onError">): React.JSX.Element {
  const mkImgUrl = useMkImgUrl();
  const [nextImageIdx, setNextImageIdx] = React.useState(0);

  return (
    <>
      {/* Image Grid */}
      <div className="grid gap-4 grid-cols-3 grid-rows-2 lg:grid-cols-3 lg:grid-rows-2">
        {[...Array(Math.max(6, images.length))].map((_, idx) => (
          <div className="h-24 w-24 relative" key={idx}>
            {images[idx]?.id ? (
              <div className="h-full w-full relative flex items-center justify-center rounded-xl overflow-hidden bg-candid-gray-500">
                {/* Image Container */}
                <UnLazyImage
                  className="absolute inset-0 w-full h-full object-cover"
                  thumbhash={images[idx].metadata_public?.thumb_hash ?? undefined}
                  src={mkImgUrl(images[idx].id, "256")} // Higher resolution for crisp display
                />
                {/* Delete Button */}
                <button
                  onClick={() => deleteImage?.(images[idx].id!)}
                  className="absolute top-1 right-1 bg-[#FF625F] w-5 h-5 rounded-full flex items-center justify-center text-white z-10"
                >
                  <FontAwesomeIcon icon={faXmark} size="xs" />
                </button>
              </div>
            ) : (
              <div className="relative h-full w-full flex items-center justify-center rounded-xl bg-candid-gray-500">
                <div className="relative flex items-center justify-center">
                  {idx === images.length ? (
                    <ImageUploader
                      iconOverride={faPlus}
                      // overridePopup={{
                      //   hide: true,
                      //   title: "Add a photo",
                      //   subtitle: "Add a photo to your profile",
                      // }}
                      disallowMultiple
                      {...imageUploaderProps}
                      onStart={() => setNextImageIdx(idx + 1)}
                      btnClassName={classNames(
                        "btn-circle btn-primary bg-[#FF625F] text-2xl flex-1",
                        "border-none absolute flex justify-center items-center"
                      )}
                      btnContent={""}
                    />
                  ) : (
                    <button
                      className={classNames(
                        "btn-circle btn-primary text-3xl flex-1",
                        "border-none absolute flex justify-center items-center bg-candid-gray-700"
                      )}
                      disabled
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export function PendingApprovalImages({
  images,
  deleteImage,
  ...imageUploaderProps
}: {
  deleteImage?: (s: string) => Promise<unknown>;
  images: OnboardingImages412Query["media_uploads"];
} & Pick<ImageUploaderProps, "onComplete" | "onStart" | "onError">): React.JSX.Element {
  const mkImgUrl = useMkImgUrl();
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
  let imagesLength = images.length;
  let shouldDisplaySmall = screenHeight <= 667;
  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [images.length]);

  return (
    <div
      className={clsx(
        "grid gap-4 w-full h-full",
        shouldDisplaySmall
          ? `grid-cols-${Math.min(imagesLength + 1, 6)}`
          : "grid-cols-3 grid-rows-2"
      )}
    >
      {Array.from({
        length: imagesLength < 6 ? imagesLength + 1 : Math.max(6, imagesLength),
      }).map((_, idx) => (
        <div
          className={clsx(
            "relative flex items-center justify-center h-auto w-full",
            shouldDisplaySmall ? "h-48" : `h-auto`
          )}
          key={idx}
        >
          {images[idx]?.id ? (
            <div className="relative items-center justify-center flex h-full w-full">
              <UnLazyImage
                className="max-w-full max-h-32 w-full h-full object-cover border-4 border-candid-gray-500 rounded-2xl"
                thumbhash={images[idx].metadata_public?.thumb_hash ?? undefined}
                src={mkImgUrl(images[idx].id, "256")}
              />
              <div className="absolute -right-4 -top-4 z-30-main-ui-overlay-2">
                <button
                  onClick={() => {
                    deleteImage?.(images[idx].id!);
                  }}
                  className={`btn bg-gray-700 btn-${
                    shouldDisplaySmall ? "xs" : "sm"
                  } btn-circle text-white border-white border-21`}
                >
                  <FontAwesomeIcon icon={faXmark} size={shouldDisplaySmall ? "xs" : "lg"} />
                </button>
              </div>
            </div>
          ) : (
            <div className="relative h-full w-full items-center justify-center flex rounded-2xl">
              <div className="relative flex items-center justify-center w-full h-full">
                {idx === images.length ? (
                  <ImageUploader
                    iconOverride={faPlus}
                    disallowMultiple
                    btnClassName={clsx(
                      "btn-circle btn-primary",
                      shouldDisplaySmall ? "btn-sm text-lg" : "btn-lg text-3xl",
                      "flex-1 border-none absolute flex justify-center items-center"
                    )}
                    btnContent=""
                    {...imageUploaderProps}
                  />
                ) : (
                  <button
                    className={classNames(
                      "btn-circle btn-primary text-3xl flex-1",
                      "border-none absolute flex justify-center items-center bg-candid-gray-700"
                    )}
                    disabled
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
