import { Feature_Flags_Enum, Onboarding_Statuses_Enum } from "shared/dist/__generated__/components";
import { P, match } from "ts-pattern";
import {
  PermissionStateWithExtras,
  PermissionType,
  useGetLocalPermissionState,
  useRequestPermissions,
} from "./permissions-context";
import {
  faCircleCheck as faCircleCheckSolid,
  faExclamationTriangle,
} from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4 } from "shared-web-react/dist/widgets/text";
import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import clsx from "clsx";
import { faCircleCheck as faCircleCheckOutline } from "@fortawesome/pro-regular-svg-icons";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useMySlug } from "../screens/bio/bio-settings";
import { Button } from "../components/atoms/Button";

function CheckIndicator({
  checked,
  loading,
}: {
  checked: PermissionStateWithExtras;
  loading: boolean;
}): React.JSX.Element {
  const size = "2x";
  if (loading) return <Spinner size={size} />;
  const granted = checked === "granted";
  const disabledColor = match(checked)
    .with("granted", "prompt", "prompt-with-rationale", () => false)
    .otherwise(() => true);
  return checked === "denied" ? (
    <FontAwesomeIcon icon={faExclamationTriangle} className={"text-error"} size={size} />
  ) : (
    <FontAwesomeIcon
      icon={granted ? faCircleCheckSolid : faCircleCheckOutline}
      className={disabledColor ? "text-neutral" : "text-green-600"}
      size={size}
    />
  );
}

function PermGridRow({ permType }: { permType: PermissionType }): React.JSX.Element {
  const permState = useGetLocalPermissionState(permType);
  const disabled = match(permState)
    .with("prompt-with-rationale", "prompt", () => false)
    .otherwise(() => true);
  return (
    <React.Fragment>
      <div
        className={clsx("flex items-center justify-center w-10 h-10 ", disabled && "opacity-50")}
      >
        <CheckIndicator loading={false} checked={permState} />
      </div>
      <div
        className={clsx(
          "flex flex-col justify-center items-stretch text-left",
          disabled && "opacity-50"
        )}
      >
        <p className={clsx()}>
          {match(permType)
            .with("geolocation", () => (
              <span>
                <span className="font-semibold mr-2">Geolocation:</span>
                Allows Simmr to give much better, more local discovery results and suggestions.
              </span>
            ))
            .with("pushNotifications", () => (
              <span>
                <span className="font-semibold mr-2">Push Notifications:</span>
                Never miss a match or a message from a great connection on Simmr.
              </span>
            ))
            .exhaustive()}
        </p>
        {disabled && (
          <p className={clsx("italic")}>You can change this in the device Settings app.</p>
        )}
      </div>
    </React.Fragment>
  );
}

const PermsDialogContext = React.createContext<{ canDisplay: boolean; trigger: () => void }>({
  canDisplay: false,
  trigger: () => {},
});

export function usePermissionsDialog() {
  return React.useContext(PermsDialogContext);
}

export function PermissionRequestCheckProvider({
  children,
}: React.PropsWithChildren<{}>): React.JSX.Element {
  const ref = React.createRef<HTMLDialogElement>();
  const { status } = useMySlug() ?? {};
  const pushNotificationsLocalState = useGetLocalPermissionState("pushNotifications");
  const geoLocationLocalState = useGetLocalPermissionState("geolocation");
  const enabled = useIsFeatureEnabled(Feature_Flags_Enum.NativePermissionsV01);
  const canDisplay =
    enabled &&
    match([pushNotificationsLocalState, geoLocationLocalState])
      .with(["granted", "granted"], () => true)
      .with(["denied", "denied"], () => true)
      .with(["unavailable", "unavailable"], () => false)
      .with(["loading", "loading"], () => true)
      .otherwise(() => true);
  const trigger = React.useCallback(() => {
    if (!enabled) return;
    if (status !== Onboarding_Statuses_Enum.V2_20LiveUser) return;
    match([pushNotificationsLocalState, geoLocationLocalState])
      .with(["unavailable", "unavailable"], () => ref?.current?.close?.())
      .otherwise(() => ref?.current?.showModal?.());
  }, [ref, enabled]);
  const [shownOnLaunch, setShownOnLaunch] = React.useState(false);
  const requestFunctions = {
    pushNotifications: useRequestPermissions("pushNotifications"),
    geolocation: useRequestPermissions("geolocation"),
  };
  React.useEffect(() => {
    // only show on launch if one of the permissions is ready for a prompt
    if (!enabled) return;
    if (shownOnLaunch) return;
    if (status !== Onboarding_Statuses_Enum.V2_20LiveUser) return;
    match([pushNotificationsLocalState, geoLocationLocalState])
      .with(
        [P._, "prompt"],
        [P._, "prompt-with-rationale"],
        ["prompt", P._],
        ["prompt-with-rationale", P._],
        () => {
          setShownOnLaunch(true);
          ref?.current?.showModal?.();
        }
      )
      // .with(["granted", "granted"], () => ref?.current?.close?.())
      // .with(["denied", "denied"], () => ref?.current?.close?.())
      // .with(["unavailable", "unavailable"], () => ref?.current?.close?.())
      // .with(["loading", "loading"], () => ref?.current?.close?.())
      .otherwise(() => {});
  }, [ref, shownOnLaunch, setShownOnLaunch]);
  const onNext = React.useCallback(() => {
    match([pushNotificationsLocalState, geoLocationLocalState])
      .with([P._, "prompt"], [P._, "prompt-with-rationale"], () => requestFunctions.geolocation())
      .with(["prompt", P._], ["prompt-with-rationale", P._], () =>
        requestFunctions.pushNotifications()
      )
      .with(["loading", "loading"], () => {})
      .otherwise(() => ref?.current?.close?.());
  }, [pushNotificationsLocalState, geoLocationLocalState, requestFunctions]);
  return (
    <PermsDialogContext.Provider value={{ canDisplay, trigger }}>
      {children}
      <PortalDialog ref={ref} preventDismiss>
        <div className="flex flex-col justify-stretch items-start gap-1">
          <div className="flex flex-row w-full justify-between items-center">
            <div className="flex flex-col justify-start">
              <H4 className="">App Permissions</H4>
            </div>
          </div>
          <div className="divider my-0 py-0 mx-[-24px]"></div>
          <p>Simmr works better with if you enable some permissions in our app.</p>
          <div className="grid pt-2 gap-4 " style={{ gridTemplateColumns: "28px 1fr" }}>
            <PermGridRow permType="geolocation" />
            <PermGridRow permType="pushNotifications" />
            <div /> {/* spacer */}
          </div>
          {/* <button
            className={clsx("outline-none focus:outline-none btn btn-primary w-full")}
            onClick={onNext}
          >
            {match([pushNotificationsLocalState, geoLocationLocalState])
              .with([P._, "prompt"], [P._, "prompt-with-rationale"], () => "next")
              .with(["prompt", P._], ["prompt-with-rationale", P._], () => "next")
              .with(["loading", "loading"], () => "")
              .otherwise(() => "close")}
          </button> */}
          <Button
            className="w-full"
            onClick={onNext}
            variant="outline"
            disabled={match([pushNotificationsLocalState, geoLocationLocalState])
              .with([P._, "prompt"], [P._, "prompt-with-rationale"], () => false)
              .with(["prompt", P._], ["prompt-with-rationale", P._], () => false)
              .otherwise(() => true)}
          >
            {match([pushNotificationsLocalState, geoLocationLocalState])
              .with([P._, "prompt"], [P._, "prompt-with-rationale"], () => "next")
              .with(["prompt", P._], ["prompt-with-rationale", P._], () => "next")
              .with(["loading", "loading"], () => "")
              .otherwise(() => "close")}
          </Button>
        </div>
      </PortalDialog>
    </PermsDialogContext.Provider>
  );
}

export function PermissionsDebugging({}): React.JSX.Element {
  const pushLocal = useGetLocalPermissionState("pushNotifications");
  const geoLocation = useGetLocalPermissionState("geolocation");
  return (
    <div>
      <div>Push: {pushLocal}</div>
      <div>GeoLoc: {geoLocation}</div>
    </div>
  );
}
