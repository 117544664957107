import {
  LazyImage,
  LazyImageProps,
  useLoadImageWithCache,
} from "shared-web-react/dist/widgets/lazy-image";
import { Size, useMkImgSrcSet, useMkImgUrl } from "../util/routes";

import React from "react";
import clsx from "clsx";

export type LazyMediaItemProps = Omit<LazyImageProps, "src"> & {
  mediaUploadId: string;
  size?: Size;
  overrideVisibility?: boolean;
};

export function useLoadMediaItemIntoCache() {
  const mkUrl = useMkImgUrl();
  const { loadImage } = useLoadImageWithCache();
  return React.useCallback(
    async (mediaUploadId: string, size?: Size): Promise<string | null> => {
      const url = mkUrl(mediaUploadId, size);
      return await loadImage(url);
    },
    [loadImage, mkUrl]
  );
}

export function LazyMediaItem({
  mediaUploadId,
  size,
  thumbHash,
  alt,
  className,
  imgRef,
  overrideVisibility,
  ...props
}: LazyMediaItemProps): React.JSX.Element {
  const mkUrl = useMkImgUrl();
  const url = mkUrl(mediaUploadId, size);
  const [hasError, setHasError] = React.useState(false);

  // Create a more robust error handler
  const handleError = React.useCallback(() => {
    console.error(`LazyMediaItem: Failed to load image with ID: ${mediaUploadId}, size: ${size}`);
    setHasError(true);
  }, [mediaUploadId, size]);

  // Create a load handler that can be composed with any passed onLoad
  const handleLoad = React.useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (props.onLoad) {
        props.onLoad(e);
      }
    },
    [props.onLoad]
  );

  return (
    <LazyImage
      src={url}
      {...props}
      thumbHash={thumbHash}
      alt={alt || "Media content"}
      className={clsx("LazyMediaItem", className)}
      imgRef={imgRef}
      onError={handleError}
      onLoad={handleLoad}
      overrideVisibility={overrideVisibility}
    />
  );
}
